<template>
  <div>
    <div class="content">
      <PageHeader title="Add an account" />
      <div class="block block block-rounded">
        <!-- Step Tabs -->
        <ul class="nav nav-tabs nav-tabs-block nav-justified" role="tablist">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: step === 1, disabled: step === 3 }" href="#" @click="onClickPrevStep">1. Asset</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: step === 2, disabled: step === 3 }" href="#" @click="onClickNextStep">2. Details</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ disabled: step !== 2 }">3. Confirmation</a>
          </li>
        </ul>
        <!-- END Step Tabs -->

        <!-- Form -->
        <div>
          <!-- Wizard Progress Bar -->
          <div class="block-content block-content-sm">
            <div class="progress" data-wizard="progress" style="height: 8px">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                role="progressbar"
                :style="`width: ${(step * 100) / totalSteps}%;`"
                aria-valuenow="30"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <!-- END Wizard Progress Bar -->

          <!-- Steps Content -->
          <div class="block-content block-content-full tab-content" style="min-height: 300px">
            <!-- Step 1 -->
            <div class="tab-pane" :class="{ active: step === 1 }" data-cy="accounts-form" role="tabpanel">
              <SectionTitle>Select an asset</SectionTitle>
              <div class="row push">
                <div class="col-lg-4">
                  <p class="text-muted"></p>
                </div>
                <div class="col-lg-8 col-xl-4">
                  <Spinner v-if="assetLoadingAction.list" />
                  <div v-else>
                    <FormGroup
                      id="assetId"
                      v-model="form.assetId"
                      :error="validationErrors['assetId']"
                      :options="filteredAssets"
                      type="select2"
                      :config="{ allowSearch: true }"
                      label="Asset"
                      @input="onSelectAsset"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- END Step 1 -->

            <!-- Step 2 -->
            <div v-if="form.assetId" class="tab-pane" :class="{ active: step === 2 }">
              <div v-if="error.message" class="alert alert-danger" role="alert">
                <h3 class="alert-heading font-size-h4 my-2">Error</h3>
                <div class="mb-0">
                  <strong>{{ error.message }}</strong>
                  <div v-for="(error, key) in validationErrors" :key="key">{{ error }}</div>
                </div>
              </div>
              <AccountForm
                v-model="form"
                :account-id="accountId"
                :error="validationErrors"
                country-code="gb"
                :is-create="true"
                :asset-id="form.assetId"
              />
            </div>
            <!-- END Step 2 -->

            <!-- Step 3 -->
            <div class="tab-pane" :class="{ active: step === 3 }">
              <SectionTitle>Confirmation</SectionTitle>

              <div v-if="loading.submit" class="d-flex justify-content-center align-items-center flex-column py-5">
                <div class="spinner-border spinner-lg text-secondary my-5" role="status"></div>
                <p>Please wait while the Account is created...</p>
              </div>
              <div v-else class="text-center">
                <p v-if="accountCreated && !error.message" class="text-muted font-w600">
                  <i class="fa fa-fw fa-check-circle text-success"></i> The account was created successfully.
                  <router-link
                    :to="{ name: 'asset-accounts-overview', params: { id: form.assetId, accountId: createdAccount._id } }"
                    data-cy="account-dashboard-btn"
                    >Click here</router-link
                  >
                  to go to the account.
                </p>

                <p v-if="error.message">There was an error creating this account. Go back to Step 2.</p>
              </div>
            </div>
            <!-- END Step 3 -->
          </div>
          <!-- END Steps Content -->

          <!-- Steps Navigation -->
          <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
              <div class="col-6">
                <button v-if="step === 2" type="button" class="btn btn-primary" data-wizard="prev" @click="onClickPrevStep">
                  <i class="fa fa-angle-left mr-1"></i> Previous
                </button>
              </div>
              <div class="col-6 text-right">
                <button v-if="step === 1" data-cy="next-btn" type="button" class="btn btn-primary" @click="onClickNextStep">
                  Next
                  <i class="fa fa-angle-right ml-1"></i>
                </button>
                <button v-if="step === 2" data-cy="create-btn" type="submit" class="btn btn-primary" @click="onClickSubmit">
                  <i class="fa fa-check-circle mr-1"></i> Create Account
                </button>
                <button v-if="step === 3 && accountCreated" data-cy="create-another-btn" class="btn btn-success" @click="onCreateAnother">
                  <i class="fa fa-fw fa-plus-circle mr-1"></i>Create another
                </button>
              </div>
            </div>
          </div>
          <!-- END Steps Navigation -->
        </div>
        <!-- END Form -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import AccountForm from '@/components/forms/AccountForm';
import FormGroup from '@/components/FormGroup';
import PageHeader from '@/components/PageHeader';
import SectionTitle from '@/components/base/SectionTitle';
import Spinner from '@/components/SpinnerLogo';

const initialForm = {
  assetId: '',
  type: 'electricity',
  meterType: 'primary',
  contractStatus: 'active',
  status: 'active',
  meterOperator: '',
  meterOperatorType: 'lease',
  meterUnits: 'kwh',
  reportingType: null,
  meterUser: 'landlord',
  automaticMeterRead: false,
  name: '',
  supplierId: ''
};

export default {
  name: 'AccountWizard',
  components: {
    AccountForm,
    FormGroup,
    PageHeader,
    SectionTitle,
    Spinner
  },
  data() {
    return {
      step: 1,
      totalSteps: 3,
      entityId: '',
      form: { ...initialForm },
      isSubmitting: false,
      accountCreated: false,
      accountId: ''
    };
  },
  computed: {
    ...mapGetters({
      assets: 'asset/assets',
      assetLoadingAction: 'asset/loadingAction',
      loading: 'accountWizard/loading',
      error: 'accountWizard/error',
      validationErrors: 'accountWizard/validationErrors',
      account: 'accountWizard/account',
      createdAccount: 'accountWizard/createdAccount'
    }),
    filteredAssets() {
      const assets = [...this.assets];

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return [{ label: '', value: '' }, ...assets.map(asset => ({ label: asset.siteName, value: asset._id }))];
    }
  },
  async mounted() {
    this.resetWizard();

    if (this.$route.query.assetId) {
      this.form.assetId = this.$route.query.assetId;
      this.step = 2;
    }

    await this.listAssets({ data: { params: { $select: 'siteName,entityId,companyId,address,assetType', $limit: 5000 } } });
  },
  methods: {
    ...mapActions({
      listAssets: 'asset/list',
      submitWizard: 'accountWizard/submit',
      getAccounts: 'asset/accounts'
    }),
    ...mapMutations({
      resetWizard: 'accountWizard/RESET_WIZARD',
      setValidationErrors: 'accountWizard/SET_VALIDATION_ERRORS'
    }),
    onSelectAsset(assetId) {
      this.asset = this.assets.find(asset => asset._id === assetId);
    },
    onClickStep(step) {
      this.step = step;
    },
    onClickNextStep() {
      const errors = {};

      if (!this.form.assetId) errors.assetId = 'Please select an Asset';

      if (Object.keys(errors).length === 0) {
        this.step = this.step >= this.totalSteps ? this.totalSteps : this.step + 1;
      }

      this.setValidationErrors(errors);
    },
    onClickPrevStep() {
      this.step = this.step <= 1 ? 1 : this.step - 1;
    },
    async onClickSubmit() {
      this.step = 3;

      await this.submitWizard({
        ...this.form,
        companyId: this.$auth.companyId
      });

      if (this.error.message) {
        window.scrollTo(0, 0);
        this.step = 2;
      } else {
        this.accountCreated = true;
        this.getAccounts({ id: this.form.assetId, data: { params: { $sort: 'name:1', $deleted: true } } });
      }
    },
    async onCreateAnother() {
      this.resetWizard();
      this.form = { ...initialForm };
      await this.setState();
      this.entityId = '';
      this.isSubmitting = false;
      this.accountCreated = false;
      this.step = 1;
    },
    getError(field) {
      if (this.error.data && this.error.data.errors && this.error.data.errors[field] && this.error.data.errors[field].message) {
        return this.error.data.errors[field].message;
      }

      return null;
    }
  }
};
</script>
<style></style>
